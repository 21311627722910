import React from "react"
import AirtimePage from "../../components/body/pages/en-ng/airtime"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Airtime = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/airtime/"}
      title="Buy Airtime & Internet Data in Nigeria | Fast Recharge | Kuda"
      description="Buy Airtel, Glo, MTN and 9Mobile airtime and internet data directly from your Kuda App. We save your numbers making data & airtime recharges easy."
    />
    <AirtimePage />
  </Layout>
)

export default Airtime
